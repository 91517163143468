import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import TextInput from '../components/fields/text-input';
import loadFunctions from '../components/firebase-functions';
import Logo from '../images/logo';
import SEO from '../components/seo';

const NutritionCalculator = () => {
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const step = params.get('step');
        setShowForm(step === 'contact');
        setShowConfirmationPopup(step === 'confirm-popup');
    });

    const handleInputChange = (name: string, value: string) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        try {
            const functions = await loadFunctions();
            await functions.httpsCallable('subscribeUserForFreeCalculator')({
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout noFooter={true} noHeader={true}>
            <SEO
                title="Get Free Nutrition Calculator | J Clarke Fitness"
                description="Read about my mission and what I am doing to redefine how people view fitness. Workouts and meal plans that complement and enhance your lifestyle."
            />
            <div>
                {!showForm && !showConfirmationPopup && (
                    <div className="center-container">
                        <div className="center-content">
                            <Logo />
                            <h2 className="title">
                                FREE CALORIE & MACRO CALCULATOR TO OPTIMISE YOUR NUTRITION
                            </h2>
                            <button className="recipe_book_btn">
                                <Link to="/nutrition-calculator?step=contact">GET IT NOW</Link>
                            </button>
                        </div>
                    </div>
                )}

                {showForm && !showConfirmationPopup && (
                    <div className="center-container">
                        <div className="recipe-book-form center-content">
                            <Logo />
                            <h2 className="title">Where should I send the calculator?</h2>
                            <form onSubmit={handleSubmit} className="form">
                                <div className="name_inputs">
                                    <TextInput
                                        label="First name"
                                        value={formData.firstName}
                                        required={true}
                                        onChange={value => handleInputChange('firstName', value)}
                                    />
                                    <TextInput
                                        label="Last name"
                                        value={formData.lastName}
                                        required={true}
                                        onChange={value => handleInputChange('lastName', value)}
                                    />
                                </div>
                                <TextInput
                                    label="Email"
                                    value={formData.email}
                                    required={true}
                                    onChange={value => handleInputChange('email', value)}
                                    type="email"
                                />

                                <button
                                    type="submit"
                                    className="send_book_btn"
                                    onClick={handleSubmit}
                                    disabled={
                                        loading ||
                                        !formData.firstName ||
                                        !formData.lastName ||
                                        !formData.email
                                    }
                                >
                                    <Link to="/nutrition-calculator?step=confirm-popup">
                                        SEND ME THE CALCULATOR
                                    </Link>
                                </button>
                            </form>
                        </div>
                    </div>
                )}

                {showConfirmationPopup && (
                    <div className="center-container">
                        <div className="center-content">
                            <Logo />
                            <div className="confirmation-popup">
                                <h2>YOUR CALCULATOR IS ON ITS WAY TO YOUR INBOX</h2>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <style>
                {`
                    .center-container {
                        display: flex;
                        justify-content: center;
                        height: 100vh;
                        background-color: #263238;
                        padding-top: 20px;
                    }
                    .title {
                        color: white;
                        max-width: 500px;
                    }
                    .center-content {
                        text-align: center;
                        padding: 0px 20px;
                    }
                    .confirmation-popup {
                        background: #EDF6F9;
                        padding: 50px;
                        margin-top: 40px;
                    }
                    .confirmation-popup h2 {
                        margin: 0;
                        color: black;
                        font-weight: 900;
                        text-transform: capitalize;
                        font-size: 30px;
                        margin-top: 20px;
                        max-width: 500px;
                    }
                    button {
                        padding: 10px 20px;
                        background-color: #f44034;
                        color: white;
                        border: none;
                        border-radius: 3px;
                        cursor: pointer;
                        font-size: 16px;
                        margin-top: 20px;
                        font-weight: bold;
                    }
                    button a {
                        color: white;
                        text-decoration: none;
                    }
                    .recipe-book-form {
                        max-width: 500px;
                        margin: 0 auto;
                        padding: 0px 20px;
                        border-radius: 4px;
                    }
                    .form {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                      }
                      .name_inputs {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 10px;
                      }
                      .form label {
                        color: white;
                        text-align: left;
                      }
                      .send_book_btn {
                        align-self: center;
                      }
                      .send_book_btn:disabled {
                        background: #999;
                        pointer-events: none;
                        cursor: none;
                      }
                `}
            </style>
        </Layout>
    );
};

export default NutritionCalculator;
