import React from 'react';
import PropTypes from 'prop-types';

const TextInput = props => {
    const {
        label,
        validationMessage,
        type,
        value,
        onChange,
        required,
        placeholder,
        readOnly,
    } = props;

    return (
        <label>
            {label}
            <input
                className={validationMessage ? 'invalid' : ''}
                type={type || 'text'}
                value={value || ''}
                onChange={e => onChange(e.target.value)}
                required={required}
                placeholder={placeholder}
                readOnly={readOnly}
            />
            {validationMessage && <p className="error-message">{validationMessage}</p>}
        </label>
    );
};

TextInput.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    validationMessage: PropTypes.string,
    placeholder: PropTypes.string,
};

export default TextInput;
